<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

	export default {
		name: "AppRequest",
		extends: Base,
		data() {
			return {
				Name: "AppRequest",
				mrApi: {},
				mrClients: {},
				link_excel: "",
				first_date: {},
			}
		},
		async mounted() {
			await Gen.loadCss("/backend/assets/node_modules/bootstrap-daterangepicker/daterangepicker.css")
			await Gen.loadScript("/backend/assets/node_modules/bootstrap-daterangepicker/daterangepicker.js")
			this.$set(this.$root, 'page', this)
			this.refreshData()
			setTimeout(() => {
				this.initRangeDate()
			}, 200);
		},
		methods: {
			exportExcel() {
				return this.baseUrl + '/download/report-excel'
			},
			initRangeDate() {
				if ($(".linkedCalendars").val()) return;
				$(".linkedCalendars").daterangepicker({
					startDate: moment(this.first_date),
					endDate: moment(),
					opens: 'left',
					linkedCalendars: false,
				}, (start, end, label) => {
					this.$set(this.filter, 'start_date', start.format('YYYY-MM-DD'))
					this.$set(this.filter, 'end_date', end.format('YYYY-MM-DD'))
				});
				this.$set(this.filter, 'start_date', this.first_date)
				this.$set(this.filter, 'end_date', moment().format('YYYY-MM-DD'))
			}
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.api'(v) {
				if (!this.$route.params.id) this.search()
			},
			'filter.client'(v) {
				if (!this.$route.params.id) this.search()
			},
			'filter.end_date'(v) {
				if (this.filter.end_date < this.filter.start_date) return swal("End Date Less Then Start Date")
				if (!this.$route.params.id) this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row align-items-center">
								<div class="col-md-2">
									<h5 class="card-title mb-0">Filter</h5>
								</div>
								<div class="col-md-3">
									<select2 name="" v-model="filter.api" :options="mrApi"
										:object="['ma_id','ma_name']">
										<option value="">-- All API --</option>
									</select2>
								</div>
								<div class="col-md-3">
									<select2 name="" v-model="filter.client" :options="mrClients"
										:object="['mc_id','mc_name']">
										<option value="">-- All Clients --</option>
									</select2>
								</div>
								<div class="col-md-4">
									<div class='input-group'>
										<input type='text' class="form-control linkedCalendars" />
										<div class="input-group-append">
											<span class="input-group-text">
												<span class="ti-calendar"></span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List <span
											v-if="data.total">({{(data.total||0)}})</span></h5>
								</div>
								<!-- <div class="col-sm-1"></div> -->
								<div class="col-sm-3 text-right">
									<a v-if="!NotFound" :href="link_excel" target="_blank"
										class="btn btn-success">Export to Excel</a>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search"
												class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="ar_create_at"></SortField>
									</th>
									<th>{{fields.ar_mc_id}}</th>
									<th>{{fields.ar_ma_id}}</th>
									<th>{{fields.ar_request}}</th>
									<th>{{fields.ar_response}}</th>
									<th>{{fields.ar_response_code}}</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.ar_create_at||'').dates('format2')}}</td>
									<td>{{v.mc_name}}</td>
									<td>{{v.ma_name}}</td>
									<td>{{(v.ar_request||'').limitChar(200)}}</td>
									<td>{{(v.ar_response||'').limitChar(200)}}</td>
									<td>{{v.ar_response_code}}</td>
									<td>{{v.ar_response_status}}</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>